/* montserrat-black */
@font-face {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 900;
	src: local("Montserrat Black"), local("Montserrat-Black"), url(montserrat-black.woff2) format("woff2"), url(montserrat-black.woff) format("woff");
	font-display: swap;
}
/* montserrat-bold */
@font-face {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 700;
	src: local("Montserrat Bold"), local("Montserrat-Bold"), url(montserrat-bold.woff2) format("woff2"), url(montserrat-bold.woff) format("woff");
	font-display: swap;
}
/* montserrat-extrabold */
@font-face {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 400;
	src: local("Montserrat ExtraBold"), local("Montserrat-ExtraBold"), url(montserrat-extrabold.woff2) format("woff2"), url(montserrat-extrabold.woff) format("woff");
	font-display: swap;
}
/* montserrat-extralight */
@font-face {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 400;
	src: local("Montserrat ExtraLight"), local("Montserrat-ExtraLight"), url(montserrat-extralight.woff2) format("woff2"), url(montserrat-extralight.woff) format("woff");
	font-display: swap;
}
/* montserrat-light */
@font-face {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 300;
	src: local("Montserrat Light"), local("Montserrat-Light"), url(montserrat-light.woff2) format("woff2"), url(montserrat-light.woff) format("woff");
	font-display: swap;
}
/* montserrat-medium */
@font-face {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	src: local("Montserrat Medium"), local("Montserrat-Medium"), url(montserrat-medium.woff2) format("woff2"), url(montserrat-medium.woff) format("woff");
	font-display: swap;
}
/* montserrat-regular */
@font-face {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 400;
	src: local("Montserrat Regular"), local("Montserrat-Regular"), url(montserrat-regular.woff2) format("woff2"), url(montserrat-regular.woff) format("woff");
	font-display: swap;
}
/* montserrat-semibold */
@font-face {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 400;
	src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"), url(montserrat-semibold.woff2) format("woff2"), url(montserrat-semibold.woff) format("woff");
	font-display: swap;
}
/* montserrat-thin */
@font-face {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 100;
	src: local("Montserrat Thin"), local("Montserrat-Thin"), url(montserrat-thin.woff2) format("woff2"), url(montserrat-thin.woff) format("woff");
	font-display: swap;
}

